.view-button {
    color: white;
    text-decoration: none;
}

.view-button:hover {
    text-decoration: none;
    color: white;
}
.page-link.active, .active > .page-link{
    background-color:rgb(19 62 92);
    border-color:rgb(19 62 92);
}
.page-link{
    color: rgb(19 62 92);
}
.sub-btn{
    text-transform: capitalize;
    background-color: rgb(19 62 92) !important;
    color: white;
    float: right;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
    background-color: rgb(19 62 92) !important;
}


/* Add styles for the custom date picker class */
.custom-date-picker {
    height: 35px; /* Adjust the height as needed */
    /* Add any other styles you want */
    /* border-color: grey; */
    width: 100%;

  }
  

.time-picker-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .custom-time-picker {
    width: 150px; /* Adjust the width as needed */
    font-size: 14px; /* Adjust the font size as needed */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
  }
  
  /* Optional: Style the dropdown arrow */
  .react-time-picker__clock-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Optional: Style the clock face */
  .react-time-picker__clock {
    border: 2px solid #007bff;
  }
  
  /* Optional: Style the selected time indicator */
  .react-time-picker__clock-indicator {
    background-color: #007bff;
  }
  /* .css-b62m3t-container{
    z-index: 9999;
  } */



  @media only screen and (max-width: 600px) {
    .hide-in-mobile {
      display: none;
    }
  }
  
  /* Hide something in desktop view */
  @media only screen and (min-width: 601px) {
    .hide-in-desktop {
      display: none;
    }
  }
  .hh-grayBox {
    background-color: #F8F8F8;
    margin-bottom: 20px;
    padding: 35px;
    margin-top: 20px;
  }
  .pt45{padding-top:45px;}
  .order-tracking{
    text-align: center;
    width: 33.33%;
    position: relative;
    display: block;
  }
  .order-tracking .is-complete{
    display: block;
    position: relative;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0px solid #AFAFAF;
    background-color: #f7be16;
    margin: 0 auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    z-index: 2;
  }
  .order-tracking .is-complete:after {
    display: block;
    position: absolute;
    content: '';
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    left: 5px;
    margin: auto 0;
    border: 0px solid #AFAFAF;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  .order-tracking.completed .is-complete{
    border-color: #27aa80;
    border-width: 0px;
    background-color: #27aa80;
  }
  .order-tracking.completed .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 11px;
    opacity: 1;
  }
  .order-tracking p {
    color: #A4A4A4;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
  }
  .order-tracking p span{font-size: 14px;}
  .order-tracking.completed p{color: #000;}
  .order-tracking::before {
    content: '';
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    left: calc(-50% + 20px);
    z-index: 0;
  }
  .order-tracking:first-child:before{display: none;}
  .order-tracking.completed:before{background-color: #27aa80;}
  