.custom-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .loader-logo img {
    width: 100px;
    height: auto;
  }
  
  /* Styling for the moving circle */
  .loader-circle {
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-top-color: #3498db; /* Color of the circle */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animation for rotation */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }